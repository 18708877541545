import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import PortfolioCard from "./PortfolioCard";

export default function LastProjects() {
    const { t } = useTranslation();
    const { allPortfolioDataJson } = useStaticQuery(
        graphql`
            query {
              allPortfolioDataJson(limit: 3) {
                nodes {
                  name
                  link
                  src {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          `
    );

    return (
        <>
            <h2>{t('ourPortfolioLastProjectsText2', {projects: 3})}</h2>
            <Row>
                {allPortfolioDataJson.nodes.map((item, index) => {
                    const portfolioImage = getImage(item.src);

                    return (
                        <PortfolioCard item={item} portfolioImage={portfolioImage} index={index} />
                    )
                })}
            </Row>
        </>
    )
}